import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/TextShell'

const Section = styled.section``

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;
  }

  & p {
    padding-bottom: 0.69rem;
  }
`

const ImageWrapper = styled.div``

const Image = styled(Plaatjie)`
  & img {
    object-fit: contain !important;
  }
`

const TextIcon: React.FC<TextProps> = ({ fields }) => (
  <Section
    id={fields.sectionid}
    className="position-relative py-5 mb-lg-5 pb-lg-5"
  >
    <div className="container pt-3">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <div className="position-relative row justify-content-between ">
            <div
              className={`col-3 col-md-6 col-xl-4 mt-5 mt-lg-0 order-sm-2 mb-5 mb-sm-0 ${
                fields.options?.alignment === 'right'
                  ? 'text-start'
                  : 'text-end'
              }`}
            >
              <ImageWrapper>
                <Image image={fields.icon} alt="" className="w-100" />
              </ImageWrapper>
            </div>
            <div
              className={`col-9 col-md-6 col-xl-7 order-sm-1 ${
                fields.options?.alignment === 'right'
                  ? 'order-sm-2'
                  : 'order-sm-1'
              }`}
            >
              <Content content={fields.description} className="pe-lg-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default TextIcon
